import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import Logo from "../assets/logo.svg"
import LogoScrolled from "../assets/logo--scrolled.svg"
import LogoVentures from "../assets/logo-ventures.svg"
import IS_CORPORATE from '../config/siteConfig';


class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active: false }

    this.handleClick = this.handleClick.bind(this);
    //this.closeNav = this.closeNav.bind(this)
  }
  closeNav = () => {
    this.setState({active:false});
  }
  handleClick() {
    this.setState(function(prevState) {
      return { active: !prevState.active }
    })
  }
  render() {
    const {siteTitle,isCorporate} = this.props;
    return (
      <header>
        {/*<div className="newsticker">
          <div className="container">
            <div className="language-switcher">
              <Link hrefLang="de-de" to="/">
                DE
              </Link>
              <span>|</span>
              <Link hrefLang="en-gb" to="/en">
                EN
              </Link>
            </div>
          </div>
    </div>*/}
        <div className={`header-wrapper ${this.state.active && 'active'}`}>
        <Headroom>
          <div className={`header-content container ${this.state.active && 'active'}`}>
              <Link onClick={this.closeNav} className="logo-holder" to="/">
                {IS_CORPORATE ? <Logo className="logo" alt={`Logo: ${siteTitle}`} />
 :              <LogoVentures className="logo" alt={`Logo: ${siteTitle}`} />
}
              <LogoScrolled style={{display:'none'}} className="logo--scrolled" alt={`Logo: ${siteTitle}`} />
            </Link>
            <nav className={"nav " + (this.state.active && "active")}>
              <ul>
                  <li>
                    <Link onClick={this.closeNav} to="/koepfe">Köpfe</Link>
                  </li>
                <li>
                    <Link onClick={this.closeNav} to="/portfolio">Portfolio</Link>
                </li>
                
                { !IS_CORPORATE &&
                <li>
                    <Link onClick={this.closeNav} to="/dabei-sein">Dabei sein?</Link>
                </li>
                }
                { IS_CORPORATE &&
                <li>
                    <Link target="_blank" to="https://brueckenkoepfe-ventures.de/dabei-sein">Dabei sein?</Link>
                </li>
                }
              
                { IS_CORPORATE &&
                <li>
                    <Link onClick={this.closeNav} to="/blog">Blog</Link>
                </li>
                }
                { IS_CORPORATE &&
                <li>
                    <Link onClick={this.closeNav} to="/publikationen">Publikationen</Link>
                </li>
                 }
                 { IS_CORPORATE &&
                <li>
                    <Link onClick={this.closeNav} to="/kontakt">Kontakt</Link>
                </li>
                }
                {
                  ! IS_CORPORATE &&
                  <li className="seperator">
                    <Link onClick={this.closeNav} to="https://brueckenkoepfe.de">Die BrückenKöpfe</Link>
                </li>
                }
              </ul>
            </nav>
           
              <div
                onClick={this.handleClick}
                className={
                  this.state.active ? "nav__toggle active" : "nav__toggle"
                }
              >
                <span>Menü</span>
              </div>
            
          </div>
        </Headroom>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


{/* <div className="newsticker__paging">
          Newsticker <span>2 | 3</span>
        </div>
        <div className="newsticker__content">
          DieBrückenKöpfe bekommen eine neu Webseite mit einem Newsticker.
</div> */}