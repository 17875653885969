import React from "react"
import PropTypes from "prop-types"
import i18n from "../../config/i18n"
import "../styles/styles.scss";
import Header from "./header"
import Footer from "./footer";
import IS_CORPORATE from '../config/siteConfig';

const LocaleContext = React.createContext()



const Layout = ({ children, pageContext: { locale } }) => {
    return (
      <LocaleContext.Provider value={{ locale, i18n }}>
        <div className={IS_CORPORATE ? 'bk' : 'bk-ventures'}>
          <Header />
          <main>{children}</main>
          <Footer />
        </div>
      </LocaleContext.Provider >
)}

export { LocaleContext, Layout}

  Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    pageContext: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }).isRequired,
  }


