module.exports  = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Die BrückenKöpfe", // Navigation and Site Title
  titleAlt: "Die BrückenKöpfe", // Title for JSONLD
  description: "MehrWerte für Gesundheit schaffen",
  headline: "MehrWerte für Gesundheit schaffen", // Headline for schema.org JSONLD
  url: "https://brueckenkoepfe.de", // Domain of your site. No trailing slash!
  logo: "/assets/logo.svg", // Used for SEO
  ogLanguage: "de_DE", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "brueckenkoepfe", // shortname for manifest. MUST be shorter than 12 characters
  author: "Die Brückenköpfe", // Author for schemaORGJSONLD
  hemeColor: "#004051",
  backgroundColor: "#FFFFFF",

  //twitter: "@starter_prismicio-i18n", // Twitter Username
  //facebook: "die", // Facebook Site Name
  googleAnalyticsID: "UA-139887232-1",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
}