import React from 'react';
import Logo from "../assets/logo.svg"
import { Link } from 'gatsby';
import IS_CORPORATE from '../config/siteConfig';

const Footer = () => {
    return (
        <>
            {/* 
        <div className="footer__logo">
            <Logo className="logo" />
        </div>
        */}
            <footer>
                <div className="container two-cols">
                    <div>
                    <a href="https://die-brueckenkoepfe.workwise.io" target="_blank" rel="noopener norefferer">
                        Jobs
                    </a>{' | '}
                        <Link hrefLang="de-DE" to="/impressum">
                            Impressum

                        </Link> |  <Link hrefLang="de-DE" to="/datenschutz">
                            Datenschutz
                        </Link>{!IS_CORPORATE && <span> |  <Link hrefLang="de-DE" to="/esg-offenlegungen">
                            ESG
                        </Link></span>}
                    </div>
                    <div>
                        © {new Date().getFullYear()}, {IS_CORPORATE ? 'Die Brückenköpfe GmbH' : 'BK Ventures Management GmbH'}
                    </div>
                </div>



            </footer>
        </>
    );
};

export default Footer;